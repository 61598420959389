@import 'styles/texts';
@import '../charts';

.card {
  @include charts;

  width: 1170px;
  background: var(--white100);
  border-radius: 4px;
  box-shadow: var(--elevation16);
}
