@import 'styles/texts';
@import 'styles/three-point-reader';
@import '../../rankingsCard';

.base {
  width: 100%;
}

@include cardTable;

.medicalInstitution {
  width: calc(180px + 12px);
}

.medicalInstitutionNameText {
  @include typography('dense', 14px, bold);
  @include three-point-reader(1);

  margin-bottom: 8px;
}

.medicalInstitutionAddress {
  display: flex;
  align-items: center;
  color: var(--gray60-alpha);
}

.medicalInstitutionAddressText {
  @include three-point-reader(1);
}

.icon {
  width: 20px !important;
  height: 20px !important;
  margin-right: 4px;
}

.referralCountBefore,
.referralCountAfter {
  width: calc(72px + 12px);
}

.increaseCount {
  width: calc(90px + 12px);
}

.increaseCountSort {
  display: inline-flex;
  align-items: center;
}

.increaseCountSortText {
  margin-right: 8px;
}

.sortIconDesc {
  border-color: var(--gray100) transparent transparent transparent;
  border-width: 6px 5px 0;
}

.sortIconAsc {
  border-color: transparent transparent var(--gray100) transparent;
  border-width: 0 5px 6px;
}

.increaseCountText {
  font-weight: bold;
}

.increaseCountTextPlus {
  color: var(--secondary-green50);
}

.increaseCountTextMinus {
  color: var(--red50);
}

.borderTop {
  border-top: 1px solid var(--gray5);
}

.emptyText {
  padding: 260px 16px;
  color: var(--gray60-alpha);
  text-align: center;
}

.centeringContainer {
  padding: 260px 16px;
}
