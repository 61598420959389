$search-form-period-height: 48px;

@mixin cardHeader {
  .header {
    display: grid;
    grid-template-areas:
      'title searchFormPeriod'
      'searchFormArea searchFormArea'
      'searchFilter searchFilter';
    row-gap: 16px;
    padding: 24px;
  }

  .title {
    @include typography('dense', 20px, bold);

    display: flex;
    grid-area: title;
    align-items: center;
    min-height: $search-form-period-height;
  }

  .searchFormPeriod {
    grid-area: searchFormPeriod;
    margin-left: auto;
  }

  .searchFormArea {
    grid-area: searchFormArea;
  }

  .searchFilter {
    grid-area: searchFilter;
  }
}

%tr-base {
  border-top: 1px solid var(--gray20);
  border-bottom: 1px solid var(--gray20);

  & > th,
  & > td {
    padding: 16px 12px;

    &:first-child {
      padding-left: 24px;
    }

    &:last-child {
      padding-right: 24px;
    }
  }
}

@mixin cardTable {
  .head {
    background-color: var(--gray5);

    .tr {
      @extend %tr-base;

      // user agent stylesheet, destyle.css で th, td > font-weight を指定しているので、 type selector を明示して、スタイルを記述する
      th {
        @include typography('normal', 14px, bold);
      }
    }
  }

  .body {
    @include typography('normal', 14px, normal);

    background-color: var(--white100);

    .tr {
      @extend %tr-base;

      // destyle.css で td > vertical-align を指定しているので、 type selector を明示して、スタイルを記述する
      td {
        vertical-align: middle;
      }
    }
  }
}
