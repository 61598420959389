@import 'styles/texts';

.base {
  cursor: pointer;
}

.top {
  display: grid;
  // マークアップの都合上、action(主にてクリアボタン)を children の次になっているが、表示上は text の次になるようにするため
  grid-template-areas:
    'textArea actionArea'
    'childrenArea childrenArea';
  grid-template-columns: 1fr auto;
  column-gap: 8px;
}

.text {
  display: flex;
  grid-area: textArea;
  align-items: center;

  &.comfort {
    line-height: var(--comfort-line-height);
  }

  &.normal {
    line-height: var(--normal-line-height);
  }

  &.tight {
    line-height: var(--tight-line-height);
  }

  &.dense {
    line-height: var(--dense-line-height);
  }

  &.gray100 {
    color: var(--gray100);
  }

  &.gray60Alpha {
    color: var(--gray60-alpha);
  }

  &.gray20Alpha {
    color: var(--gray20-alpha);
  }
}

.required {
  @include typography('tight', 12px, normal);

  margin-left: 8px;
  color: var(--red50);
}

.helptext {
  @include typography($line-height: 'tight');

  display: flex;
  margin-left: 8px;
}

.children {
  grid-area: childrenArea;
}

.action {
  display: flex;
  grid-area: actionArea;
  height: fit-content;
}
