@import 'styles/texts';

.scrollableContainer {
  overflow-x: scroll;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.table {
  min-width: 100%;
  border-spacing: 0;
  border-collapse: separate;

  th {
    padding: 16px 12px;
    white-space: nowrap;

    // クラス指定をネストしないというルールに抵触しているが、テーブルの場合この方が整理しやすいので敢えて違反している
    &.firstColumn {
      position: sticky;
      left: 0;
      padding: 16px 12px 16px 24px;
      border-right: 1px solid var(--gray20);
    }
  }

  td {
    padding: 16px 12px;
    text-align: right;
    white-space: nowrap;
  }
}

.headerRowUpper {
  & > th,
  & > td {
    @include typography(normal, 14px, bold);

    background: var(--gray5); // trに背景色を指定すると、stickyしたセルが透過してしまうのでth, tdに背景色を指定している
  }
}

.headerRowLower {
  & > th,
  & > td {
    @include typography(normal, 14px, bold);

    background: var(--gray10);
  }
}

.bodyRow {
  & > th,
  & > td {
    @include typography(normal, 14px, normal);

    background: var(--white100);
    border-top: 1px solid var(--gray20); // border-collapse: separateの場合、trへのborder指定は効かないのでth, tdにborderを指定している
  }
}

.totalRow {
  & > th,
  & > td {
    @include typography(normal, 14px, bold);

    background: var(--gray5);
    border-top: 1px solid var(--gray20);
    border-bottom: 1px solid var(--gray20);
  }
}
