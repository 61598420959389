.base {
  display: flex;
  flex-wrap: wrap;

  .listItem {
    display: flex;
  }

  .hidden {
    display: none;
  }

  &.isVertical {
    flex-direction: column;

    .listItem {
      flex-direction: column;
    }
  }
}
