@import 'styles/texts';

.title {
  @include typography('dense', 20px, bold);
}

.prefecture,
.area {
  width: 192px;
}

.prefecture {
  z-index: calc(var(--z-index-pickerOver));
}
