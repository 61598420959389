@import 'styles/libs';

.base {
  position: relative;
  position: fixed;
  top: 0;
  z-index: var(--z-index-modal);
  width: 100vw;
  height: 100vh;
  height: calc-dvh(100);
}

.background {
  width: 100vw;
  height: 100vh;
  height: calc-dvh(100);
  background-color: rgba(57, 57, 58, 0.54);
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
