@import 'styles/texts';
@import 'styles/three-point-reader';

.base {
  width: 100%;
}

%tr-base {
  border-top: 1px solid var(--gray20);
  border-bottom: 1px solid var(--gray20);

  & > th,
  & > td {
    padding: 16px 12px;

    &:first-child {
      padding-left: 24px;
    }

    &:last-child {
      padding-right: 24px;
    }
  }
}

.head {
  background-color: var(--gray5);

  .tr {
    @extend %tr-base;

    // user agent stylesheet, destyle.css で th, td > font-weight を指定しているので、 type selector を明示して、スタイルを記述する
    th {
      @include typography('normal', 14px, bold);
    }
  }
}

.body {
  @include typography('normal', 14px, normal);

  background: var(--white100);

  .tr {
    @extend %tr-base;

    // destyle.css で td > vertical-align を指定しているので、 type selector を明示して、スタイルを記述する
    td {
      vertical-align: middle;
    }

    &:last-child {
      border-bottom-style: none;
    }
  }
}

.no {
  width: calc(42px + 24px);
}

.noText {
  @include typography('normal', 14px, bold);

  color: var(--gray60-alpha);
}

.medicalInstitutionName {
  width: calc(500px + 24px);
}

.medicalInstitutionNameText {
  @include typography('dense', 14px, bold);

  margin-bottom: 8px;
}

.address {
  @include typography('dense', 14px, normal);

  display: flex;
  align-items: center;
  color: var(--gray60-alpha);
}

.addressText {
  @include three-point-reader(1);
}

.locationOn {
  width: 20px !important;
  height: 20px !important;
  margin-right: 8px;
}

.targetDate {
  width: calc(120px + 24px);
}

.doctorAttend {
  width: 100px;
}

.referralCountBefore,
.referralCountAfter {
  width: calc(120px + 24px);
}

.increaseCount {
  width: calc(120px + 24px);
}

.increaseCountSort {
  display: inline-flex;
  align-items: center;
}

.increaseCountSortText {
  margin-right: 16px;
}

.sortIcon {
  width: 0;
  height: 0;
  border-style: solid;
}

.sortIconDesc {
  border-color: var(--gray100) transparent transparent transparent;
  border-width: 6px 5px 0;
}

.sortIconAsc {
  border-color: transparent transparent var(--gray100) transparent;
  border-width: 0 5px 6px;
}

.increaseCountText {
  font-weight: bold;
}

.increaseCountTextPlus {
  color: var(--green100);
}

.increaseCountTextMinus {
  color: var(--red50);
}

.borderTop {
  border-top: 1px solid var(--gray5);
}

.emptyText {
  color: var(--gray60-alpha);
}
