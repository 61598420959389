@import 'styles/texts';

.cardWrapper {
  margin-bottom: 24px;
}

.headWrapper {
  padding: 24px;
}

.paginationWrapper {
  text-align: right;
}
