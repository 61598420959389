@import 'styles/texts';

.axisScaleWrapperY {
  display: flex;
  flex: 1 0 0;
  align-items: flex-end;
  text-align: center;
}

.axisScaleY {
  @include typography('normal', 14px, normal);

  margin-bottom: -50%;
  color: var(--gray60-alpha);
  text-align: right;
  white-space: pre;
}

.axisScaleWrapperX {
  flex: 1 0 0;
  text-align: center;
}

.axisScaleX {
  @include typography('normal', 14px, normal);

  margin-left: -100%;
  color: var(--gray60-alpha);
}

.segmentsColumn {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: 4px;
  align-self: stretch;
}

.segment {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.segmentLabel {
  @include typography('normal', 32px, bold);
}

.segmentDescription {
  @include typography('normal', 18px, normal);
}

.fontGray {
  color: var(--gray60-alpha);
}

.fontWhite {
  color: var(--white100);
}
