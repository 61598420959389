@import 'styles/texts';
@import 'styles/three-point-reader';

.base {
  width: 100%;
  table-layout: fixed;
}

%tr-base {
  border-top: 1px solid var(--gray20);
  border-bottom: 1px solid var(--gray20);

  & > th,
  & > td {
    padding: 16px 12px;

    &:first-child {
      padding-left: 24px;
    }

    &:last-child {
      padding-right: 24px;
    }
  }
}

.head {
  background-color: var(--gray5);

  .tr {
    @extend %tr-base;

    // user agent stylesheet, destyle.css で
    // th, td > font-weight および vertical-align を指定しているので、
    // type selector を明示して、スタイルを記述する
    th {
      @include typography('normal', 14px, bold);

      vertical-align: middle;
    }
  }
}

.body {
  @include typography('normal', 14px, normal);

  background-color: var(--white100);

  .tr {
    @extend %tr-base;

    // destyle.css で td > vertical-align を指定しているので、 type selector を明示して、スタイルを記述する
    td {
      vertical-align: middle;
    }
  }
}

.medicalInstitution {
  width: calc(684px + 24px);
}

.medicalInstitutionNameText {
  @include typography('normal', 14px, bold);
}

.medicalInstitutionAddress {
  display: flex;
  align-items: center;
  margin-top: 8px;
  color: var(--gray60-alpha);
}

.medicalInstitutionAddressText {
  @include three-point-reader(1);
}

.referralCounts,
.reverseReferralCounts,
.differenceCounts {
  width: calc(130px + 24px);
}

.differenceCountsSort {
  display: inline-flex;
  column-gap: 8px;
  align-items: center;
}

.differenceCountsText {
  @include typography('normal', 14px, bold);
}

.borderTop {
  border-top: 1px solid var(--gray5);
}

.emptyText {
  padding: 260px 16px;
  color: var(--gray60-alpha);
  text-align: center;
}

.centeringContainer {
  padding: 260px 16px;
}

.locationIcon {
  width: 20px !important;
  height: 20px !important;
  margin-right: 4px;
}
