@import 'styles/texts';
@import 'styles/libs';

.base {
  @include typography('dense', 14px, normal);

  display: flex;
  align-items: center;
  color: var(--gray20-alpha);

  & > span {
    padding: 0 4px;

    @include isSp {
      padding: 0;
    }
  }
}

.button {
  @include isSp {
    padding: 12px;
  }
}

.active {
  font-weight: bold;
  color: var(--primary-blue50);
}
