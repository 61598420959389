@import 'styles/libs';

.base {
  display: flex;
  padding: 2px;

  @include isSp {
    padding: 8px;
  }
}

.input {
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: var(--white100);
  border: solid 2px var(--gray20);
  border-radius: 4px;

  @include isSp {
    width: 18px;
    height: 18px;
  }

  &:disabled {
    cursor: not-allowed;
    border: solid 2px var(--gray10);
  }

  &:checked {
    // stylelint-disable-next-line
    border: none;

    &::after {
      position: absolute;
      top: 5px;
      left: 3px;
      width: 10px;
      height: 5px;
      content: '';
      border-bottom: 2px solid var(--white100);
      border-left: 2px solid var(--white100);
      transform: rotate(-45deg);

      @include isSp {
        top: 6px;
        left: 4px;
      }
    }

    &.primary {
      background: var(--primary-blue50);
    }

    &.secondary {
      background: var(--secondary-green50);
    }

    &:disabled {
      background: var(--gray30);
    }
  }
}
