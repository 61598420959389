@import 'styles/input';

$inform-base-width: 110px;
$default-form-base-width: 76px;
$form-icon-width: 30px;
$form-cleanable-width: 35px;

.datePickerWrapper {
  position: relative;
  display: flex;
}

.isInForm {
  @include input;

  display: inline-flex;
  flex-grow: 1; // 親側で幅を決めている場合に追随して広げる
  align-items: center;
  width: $inform-base-width;
  isolation: isolate;

  // input 要素のように、 disabled attribute を指定できないので、クラスで真似する
  &.disabled {
    // color は custom-theme.less で上書きする
    // color: var(--gray20-alpha);
    background: var(--gray5);
    border: 1px solid var(--gray20);
  }

  &.showIcon {
    width: calc($inform-base-width + $form-icon-width);
  }

  &.cleanable {
    width: calc($inform-base-width + $form-cleanable-width);
  }

  &.showIcon.cleanable {
    width: calc($inform-base-width + $form-icon-width + $form-cleanable-width);
  }
}

.isDefaultForm {
  width: $default-form-base-width;
  padding-bottom: 4px;
  border-bottom: 1px solid var(--gray20);

  &.showIcon {
    width: calc($default-form-base-width + $form-icon-width);
  }

  &.cleanable {
    width: calc($default-form-base-width + $form-cleanable-width);
  }

  &.showIcon.cleanable {
    width: calc($default-form-base-width + $form-icon-width + $form-cleanable-width);
  }
}

.hasError {
  border: 1px solid var(--red50);

  &:not(.disabled):hover {
    border-color: var(--red50);
  }
}

.clearButton {
  position: absolute;
  top: 50%;
  right: 16px + 16px + 16px; // form padding right + calendar icon width + clear button margin right
  width: 12px;
  height: 12px;
  color: var(--gray60-alpha);
  cursor: pointer;
  transform: translateY(-50%);

  &:hover {
    color: var(--primary-blue50);
  }
}
