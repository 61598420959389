@import 'styles/texts';
@import '../rankingsCard';

.base {
  width: calc(50% - 8px);
}

@include cardHeader;

.paginationWrapper {
  padding: 16px 24px;
  text-align: right;
}

.monthPicker {
  width: 138px;
}
