@import 'styles/texts';

.header {
  display: grid;
  row-gap: 24px;
  margin-bottom: 16px;
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.title {
  @include typography('dense', 20px, bold);

  margin-right: auto;
}

.form {
  display: flex;
  align-items: center;
}

.calcTarget {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
}

.isDoctorAttendedWrapper {
  margin-right: auto;
}

.monthPickerWrapper,
.aggregationPeriodWrapper {
  display: flex;
  align-items: center;
}

.monthPickerWrapper {
  margin-right: 24px;
}

.monthPickerTitle,
.aggregationPeriodTitle {
  @include typography('dense', 14px, normal);

  margin-right: 8px;
}

.prefecture,
.area {
  width: 192px;
  margin-right: 24px;
}

.prefecture {
  z-index: calc(var(--z-index-pickerOver));
}

.monthPicker {
  width: 138px;
}
