@import 'styles/texts';

.base {
  @include typography('tight', 14px, normal);

  display: flex;
  justify-content: flex-start;
  width: 100%;
  color: var(--gray100);
  border-bottom: 1px solid var(--gray20);

  &.sp {
    overflow-x: auto;
  }

  & > button {
    position: relative;
    padding: 8.5px 12px;

    &:hover {
      background-color: var(--gray10-alpha);
    }
  }

  & > button.sp {
    flex: 1 0 auto;
    min-width: 80px;
    white-space: nowrap;
  }

  .active {
    @include typography('tight', 14px, bold);

    color: var(--primary-blue50);
    border-bottom: 2px solid var(--primary-blue50);
  }

  .attention::after {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 8px;
    height: 8px;
    content: '';
    background-color: var(--red100);
    border-radius: 50%;
  }
}
