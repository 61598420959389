@use 'sass:math';
/* stylelint-disable-next-line */
@import 'styles/texts';

$max-width: 464px;

.wrapper {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}

// Tooltipを上下左右に正しく配置するための空要素
.descriptionBase {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.top {
    top: 0;
    width: 100%;
  }

  &.bottom {
    bottom: 0;
    width: 100%;
  }

  &.left {
    top: 0;
    left: 0;
    height: 100%;
  }

  &.right {
    top: 0;
    right: 0;
    height: 100%;
  }
}

.description {
  position: absolute;
  z-index: 1;
  max-width: $max-width;
  color: var(--white100);
  overflow-wrap: break-word;
  background: var(--gray80-alpha);
  border-radius: 2px;

  // size
  &.small {
    @include typography(normal, 12px, bold);

    padding: 4px 8px;
  }

  &.medium {
    @include typography(normal, 14px, bold);

    padding: 8px 12px;
  }

  &.top {
    bottom: 0;
    // ツノ分を空けつつ、最大長に合わせて左右にはみ出しを許可する
    margin: 0 math.div(-$max-width, 2) 4px math.div(-$max-width, 2);

    // ToolTipのツノを下に向ける
    &::before {
      top: 100%;
      left: 50%;
      margin-left: -4px;
      border-top: 4px solid var(--gray80-alpha);
    }

    &.topLeft.triggerIcon {
      left: 0;
      margin-left: calc(50% - 10px); // 親要素の中央 - ツノの中心

      &::before {
        left: 10px; // ツノの中心
      }
    }

    &.topLeft.triggerText {
      left: 0;
      margin-left: 0;

      &::before {
        left: 10px; // ツノの中心
      }
    }

    &.topRight.triggerIcon {
      right: 0;
      margin-right: calc(50% - 10px); // 親要素の中央 - ツノの中心

      &::before {
        right: calc(10px - 4px); // ツノの中心 - ツノの幅
        left: auto;
      }
    }

    &.topRight.triggerText {
      right: 0;
      margin-right: 0;

      &::before {
        right: calc(10px - 4px); // ツノの中心 - ツノの幅
        left: auto;
      }
    }
  }

  &.bottom {
    top: 0;
    // ツノ分を空けつつ、最大長に合わせて左右にはみ出しを許可する
    margin: 4px math.div(-$max-width, 2) 0 math.div(-$max-width, 2);

    // ToolTipのツノの部分を上に向ける
    &::before {
      top: -8px;
      left: 50%;
      margin-left: -4px;
      border-bottom: 4px solid var(--gray80-alpha);
    }

    &.bottomLeft.triggerIcon {
      left: 0;
      margin-left: calc(50% - 10px); // 親要素の中央 - ツノの中心

      &::before {
        left: 10px; // ツノの中心
      }
    }

    &.bottomLeft.triggerText {
      left: 0;
      margin-left: 0;

      &::before {
        left: 10px; // ツノの中心
      }
    }

    &.bottomRight.triggerIcon {
      right: 0;
      margin-right: calc(50% - 10px); // 親要素の中央 - ツノの中心

      &::before {
        right: calc(10px - 4px); // ツノの中心 - ツノの幅
        left: auto;
      }
    }

    &.bottomRight.triggerText {
      right: 0;
      margin-right: 0;

      &::before {
        right: calc(10px - 4px); // ツノの中心 - ツノの幅
        left: auto;
      }
    }
  }

  &.right {
    left: 8px;
    // ツノ分を空けつつ、最大長に合わせて左右にはみ出しを許可する
    margin: 0 calc(8px + math.div(-$max-width, 2)) 0 0;

    // ToolTipのツノを左に向ける
    &::before {
      top: 50%;
      left: -8px;
      margin-top: -4px;
      border-right: 4px solid var(--gray80-alpha);
    }
  }

  &.left {
    right: 8px;
    // ツノ分を空けつつ、最大長に合わせて左右にはみ出しを許可する
    margin: 0 0 0 calc(8px + math.div(-$max-width, 2));

    // ToolTipのツノを右に向ける
    &::before {
      top: 50%;
      right: -8px;
      margin-top: -4px;
      border-left: 4px solid var(--gray80-alpha);
    }
  }

  // ToolTipのツノの部分
  &::before {
    position: absolute;
    content: '';
    border: 4px solid transparent;
  }
}
