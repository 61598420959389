.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 0;
}

.base {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  background: var(--white100);
  border: 2px solid var(--gray20);
  border-radius: 50%;

  .input:checked ~ & {
    border: 2px solid var(--primary-blue50);

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      content: '';
      background: var(--primary-blue50);
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }

  // disabled ~ disabled + checked
  .input:disabled ~ & {
    border-color: var(--gray10);
    // user-agent で指定している 0.7 を上書きする
    opacity: unset;
  }

  .input:disabled:checked ~ & {
    border-color: var(--gray30);

    &::after {
      background: var(--gray30);
    }
  }
}
