@mixin input {
  width: 100%;
  height: 48px;
  padding: 0 16px;
  font-size: 16px;
  line-height: var(--tight-line-height);
  background: var(--white100);
  border: 1px solid var(--gray20);
  border-radius: 4px;

  &::placeholder {
    font-weight: normal;
    color: var(--gray20-alpha);
  }

  &:focus {
    border: 1px solid var(--primary-blue50);

    &.error {
      border: 1px solid var(--red50);
    }
  }

  &:disabled {
    color: var(--gray20-alpha);
    background: var(--gray5);
    // user-agent で指定している 0.7 を上書きする
    opacity: unset;
  }
}
