@import 'styles/texts';
@import '../rankingsCard';

@include cardHeader;

.paginationWrapper {
  padding: 16px 24px;
  text-align: right;
}

.monthAggregation {
  width: 312px;
}
