.base {
  display: flex;
  flex-direction: column;
  padding: 35px;
  margin: -35px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.marginRight {
  margin-right: 16px;
}

.cardsWrapper {
  display: flex;
  align-self: flex-start;
  margin-bottom: 16px;
}
