.arrow {
  display: flex; // line-hightなどによって意図しない高さが生じるのを防ぐ
  color: var(--white100);
  background-color: var(--primary-blue50);
  border-radius: 2px;

  &.inactive {
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.26);
  }
}
