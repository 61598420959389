@import 'styles/input';

.wrapper {
  position: relative;
}

.base {
  @include input;

  padding-right: 32px;
  // NOTE: three-point-reader の mixin をこちらに適応できないため、text-overflow で対応
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  &:focus {
    // NOTE: input 側の padding で上書きされるのを防ぐ
    padding-right: 32px;
  }
}

.error {
  border: 1px solid var(--red50);
}

.icon {
  position: absolute;
  top: 50%;
  right: 8px;
  color: var(--gray100);
  // NOTE: このアイコンのポインターイベントを無視させる事で、アイコン部分を押しても select タグが反応できるようにする
  pointer-events: none;
  transform: translateY(-50%);
}

.disabled {
  color: var(--gray30);
}
