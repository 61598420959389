@import 'styles/texts';

.section {
  padding: 24px 0;
  border-top: 1px solid var(--gray20);

  &:first-child {
    padding-top: 0;
    border-top: none;
  }
}
