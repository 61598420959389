@import 'styles/texts';

.lineChart {
  /* stylelint-disable-next-line */
  :global(.recharts-tooltip-wrapper){
    z-index: calc(var(--z-index-tooltip));
  }
}

.legend {
  margin-top: 8px;
}

.listItem {
  display: inline-flex;
  align-items: center;
  color: var(--gray60-alpha);

  & + .listItem {
    margin-left: 16px;
  }
}

.surface {
  border-radius: 2px;
}

.text {
  @include typography('dense', 14px, normal);

  margin-left: 4px;
}
