.base {
  display: flex;
  align-items: center;
}

.prefecture,
.area {
  width: 192px;
  margin-right: 16px;
}

.prefecture {
  z-index: calc(var(--z-index-pickerOver));
}
