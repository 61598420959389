@import 'styles/texts';
@import 'styles/libs';

.base {
  border-radius: 4px;
}

.medium {
  width: 448px;
}

.wide {
  width: 608px;
}

.extraWide {
  width: 800px;
}

// 今のところ、 SP の幅は固定のため、同じ値を指定している
.medium,
.wide,
.extraWide {
  max-width: calc(100vw - 48px);

  @include isSp {
    display: grid;
    grid-template-rows: auto 1fr auto; // header, main, footer
    width: calc(100vw - 32px);
    max-width: initial;
    max-height: calc(100vh - 32px);
    max-height: calc(#{calc-dvh(100)} - 32px);
  }
}

.header {
  padding: 20px 24px;
  text-align: left;
  background: var(--gray100);
  border-radius: 4px 4px 0 0;

  @include isSp {
    padding: 16px;
  }
}

.title {
  @include typography('dense', 20px, bold);

  color: var(--white100);

  @include isSp {
    @include typography('dense', 14px, bold);
  }
}

.main {
  // header + margin 100px, footer + margin 100px として計算
  max-height: calc(100vh - (100px + 100px));
  padding: 24px;
  overflow-y: auto;
  background: var(--white100);

  @include isSp {
    // SP の場合は、 height 指定を、base (親要素) の grid-template-rows で指定しているため、固定値にしない
    max-height: unset;
    padding: 16px;
  }
}

.footer {
  display: flex;
  padding: 16px 24px;
  background: var(--white100);
  border-top: 1px solid var(--gray20-alpha);
  border-radius: 0 0 4px 4px;

  @include isSp {
    display: flex;
    column-gap: 8px;
    align-items: center;
    padding: 16px;
  }
}

.mainButtonsWrapper {
  display: flex;
  margin-left: auto;

  @include isSp {
    flex-grow: 1;
    column-gap: 8px;
    margin-left: 0;
  }
}

.cancelButton {
  @include isSp {
    flex-grow: 1;
  }
}

.okButtonWrapper {
  margin-left: 16px;

  @include isSp {
    display: flex;
    flex-grow: 1;
    column-gap: 8px;
    min-width: calc(50% - 4px);
    margin-left: 0;
  }
}

.okButton {
  @include isSp {
    flex-grow: 1;
  }

  & + .okButton {
    margin-left: 16px;

    @include isSp {
      margin-left: 0;
    }
  }
}
