@import 'styles/texts';
@import 'styles/three-point-reader';

// 計算用に固定値で用意
$line-height: 21px; // 14px * 150% = 21px
$padding-top-and-bottom: 4px;
$border-width: 1px;

.base {
  position: relative;
  max-height: calc((#{$line-height} + #{$padding-top-and-bottom} * 2) * 6 + #{$border-width} * 2);
  overflow-y: scroll;
  background: var(--white100);
  border: $border-width solid var(--gray20);

  &.empty {
    display: none;
  }
}

.option {
  padding: $padding-top-and-bottom 8px;
  cursor: pointer;

  &.focus {
    color: var(--white100);
    background: var(--primary-blue50);
  }
}

// line-clampさせる要素自体の上下にpaddingをつけるとwindows環境で2行目以降が見えてしまうことがある。
// これを避けるためにline-clampさせる文字列要素とpaddingをつける要素を分けている。
.optionText {
  @include typography('normal', 14px, normal);
  @include three-point-reader(1);
}
