.base {
  position: relative;
}

.buttonsContainer {
  position: absolute;
  top: 50%;
  right: 1px;
  display: inline-flex;
  flex-direction: column;
  height: calc(100% - 2px);
  border-left: 1px solid var(--gray20);
  transform: translateY(-50%);
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 50%;

  &:first-of-type {
    border-bottom: 1px solid var(--gray20);
  }

  &:disabled {
    color: var(--gray30);
    cursor: not-allowed;
  }
}
