@import 'src/components/features/DataAnalytics/Charts/charts';

@include charts;

.card {
  width: 1170px;
  background: var(--white100);
  border-radius: 4px;
  box-shadow: var(--elevation16);
}
