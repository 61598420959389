.base {
  display: flex;

  .items {
    display: flex;
    align-items: center;
  }

  &.horizontal {
    flex-wrap: wrap;
  }

  &.vertical {
    flex-direction: column;

    .items {
      flex-direction: column;
      align-items: stretch;
    }
  }
}
