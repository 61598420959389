.base {
  display: flex;
  column-gap: 8px;
  align-items: center;
}

.tilde {
  &.isDefaultForm {
    // MonthPicker 内部のスタイルと合わせるため
    padding-bottom: 5px;
  }

  &.isInForm {
    padding-bottom: unset;
  }
}
