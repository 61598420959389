@import 'styles/texts';

@mixin charts {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    padding: 0 24px;

    .title {
      @include typography(dense, 16px, bold);
    }

    .control {
      display: block;
    }
  }

  .subControls {
    display: flex;
    gap: 0 16px;
    align-items: center;
    justify-content: flex-end;
    min-height: 28px;
    padding: 0 24px;
  }

  .chart {
    padding: 24px;
  }
}
