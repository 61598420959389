// .base と .listItem は Recharts ライブラリの　Tooltip コンポーネントが
// 生成されるスタイルと同じにしたい為、例外的にデザインシステムを無視する
.base {
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
}

.listItem {
  padding: 4px 0;
}
