.charts {
  margin-top: 24px;
}

.sideBySide {
  display: flex;
  margin: 16px 0;
}

.annualReferralsCard {
  margin-right: 16px;
}
