@import 'styles/texts';
@import '../../rankingsCard';

.base {
  width: 100%;
}

@include cardTable;

.medicalInstitution {
  width: calc(360px + 24px);
}

.medicalInstitutionNameText {
  @include typography('normal', 14px, bold);
}

.address {
  width: calc(360px + 24px);
}

.addressText {
  display: flex;
  align-items: center;
  color: var(--gray60-alpha);
}

.referralCount {
  width: calc(90px + 12px);
}

.referralCountSort {
  display: inline-flex;
  align-items: center;
}

.referralCountSortText {
  margin-right: 8px;
}

.referralCountText {
  @include typography('normal', 14px, bold);
}

.sortIconDesc {
  border-color: var(--gray100) transparent transparent transparent;
  border-width: 6px 5px 0;
}

.sortIconAsc {
  border-color: transparent transparent var(--gray100) transparent;
  border-width: 0 5px 6px;
}

.borderTop {
  border-top: 1px solid var(--gray5);
}

.emptyText {
  padding: 260px 16px;
  color: var(--gray60-alpha);
  text-align: center;
}

.centeringContainer {
  padding: 260px 16px;
}

.locationIcon {
  width: 20px !important;
  height: 20px !important;
  margin-right: 4px;
}
