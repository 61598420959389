@import 'styles/texts';

.label {
  @include typography('dense', 12px, normal);

  display: block;
  margin-bottom: 8px;
}

.inputWrapper {
  display: flex;
}

.input {
  @include typography('dense', 14px, normal);

  width: 100%;

  &::placeholder {
    color: var(--gray20-alpha);
  }
}
