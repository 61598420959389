@import 'styles/texts';

.base {
  cursor: pointer;
}

.left,
.right {
  display: inline-flex;
  align-items: center;
}

.text {
  display: block;

  &.comfort {
    line-height: var(--comfort-line-height);
  }

  &.normal {
    line-height: var(--normal-line-height);
  }

  &.tight {
    line-height: var(--tight-line-height);
  }

  &.dense {
    line-height: var(--dense-line-height);
  }

  &.gray100 {
    color: var(--gray100);
  }

  &.gray60Alpha {
    color: var(--gray60-alpha);
  }

  &.gray20Alpha {
    color: var(--gray20-alpha);
  }
}
