@import 'styles/texts';

.base {
  display: inline-block;
  white-space: nowrap;
  border-radius: 40px;
}

.small {
  @include typography('dense', 12px, normal);

  padding: 8px 12px;
}

.medium {
  @include typography('dense', 14px, normal);

  padding: 12px 16px;
}

.normal {
  background-color: var(--white100);
  border: 1px solid var(--gray20);

  &:hover {
    background-color: var(--gray5);
  }
}

.focus {
  background-color: var(--gray5);
  border: 1px solid var(--gray20);
}

.active {
  color: var(--white100);
  background-color: var(--primary-blue50);

  &:hover {
    background-color: var(--primary-blue60);
  }
}
