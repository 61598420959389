@import 'styles/texts';
@import 'styles/three-point-reader';

.base {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.labelName {
  @include typography('dense', 18px, bold);
  @include three-point-reader(1);

  color: var(--gray100);
}

.inputWrapper {
  width: 343px;
}
